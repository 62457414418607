import './EventToolbar.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import GetLinkModal from 'components/Modals/GetLinkModal'
import DeleteEventModal from 'components/Modals/DeleteEventModal'
import DuplicateEventModal from 'components/Modals/DuplicateEventModal'
import Tooltip from 'components/Tooltip'

import {
  DeleteButton,
  DuplicateButton,
  GetLinkButton,
  ResetChangesButton,
  SaveButton,
  HomeButton,
  UndoButtons
} from 'components/EventToolbar/Buttons'
import UnsavedChangesModal from 'components/Modals/UnsavedChangesModal'

import { useTranslation } from 'react-i18next'

const EventToolbar = ({
  canSave,
  currentTab,
  isLoading,
  isPublished,
  undo,
  redo,
  event,
  history = [],
  index = 0,
  hasUnsavedChanges,
  previewLink,
  handlePublishEvent,
  handleUnpublishEvent,
  handleSaveEvent,
  handleResetChanges,
  handleDeleteEvent,
  debug,
  duplicateEvent,
  cannotSaveTranslationKey
}) => {
  const { t } = useTranslation()
  const [linkModalIsOpen, setLinkModalIsOpen] = useState(false)
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [duplicateModalIsOpen, setDuplicateModalIsOpen] = useState(false)
  const savePossible = canSave()

  return (
    <div className="EventToolbar">
      {currentTab === 'edit' && (
        <UndoButtons
          undo={undo}
          redo={redo}
          history={history}
          index={index}
          disabled={isLoading}
        />
      )}

      <DeleteButton
        onClick={() =>
          debug ? handleDeleteEvent() : setDeleteModalIsOpen(true)
        }
        disabled={isLoading || hasUnsavedChanges || isPublished}
        title={t('delete')}
      />

      <DuplicateButton
        onClick={() => setDuplicateModalIsOpen(true)}
        disabled={hasUnsavedChanges || isLoading}
        title={t('duplicate')}
      />

      {event && event.state !== 'DRAFT' ? (
        <GetLinkButton
          onClick={() => (debug ? previewLink() : setLinkModalIsOpen(true))}
          disabled={!event || event.state === 'DRAFT' || isLoading}
        />
      ) : null}

      <div className="EventToolbarGroup">
        <div
          className={clsx('EventToolbarSaveArea', {
            isHidden: !hasUnsavedChanges
          })}>
          <ResetChangesButton
            onClick={hasUnsavedChanges ? handleResetChanges : null}
            disabled={isLoading || !hasUnsavedChanges}
          />
          <div className="EventToolbarGroup">
            <SaveButton
              onClick={hasUnsavedChanges ? handleSaveEvent : null}
              loading={isLoading}
              disabled={isLoading || !savePossible || !hasUnsavedChanges}
            />
            {!isLoading ? (
              <div className="EventToolbarTooltipContainer">
                <Tooltip>
                  {savePossible
                    ? t('unsaved changes')
                    : t(cannotSaveTranslationKey)}
                </Tooltip>
              </div>
            ) : null}
          </div>
        </div>

        {!hasUnsavedChanges ? (
          <div className="EventToolbarHomeArea">
            <div className="EventToolbarHomeSeparator" />
            <HomeButton />
          </div>
        ) : null}
      </div>

      {linkModalIsOpen && (
        <GetLinkModal
          previewLink={previewLink}
          eventName={event && event.name}
          handleClose={() => setLinkModalIsOpen(false)}
        />
      )}

      {deleteModalIsOpen && (
        <DeleteEventModal
          handleDeleteEvent={handleDeleteEvent}
          handleClose={() => setDeleteModalIsOpen(false)}
        />
      )}

      {duplicateModalIsOpen && (
        <DuplicateEventModal
          handleClose={() => setDuplicateModalIsOpen(false)}
          event={event}
          duplicateEvent={duplicateEvent}
        />
      )}

      <UnsavedChangesModal
        hasUnsavedChanges={hasUnsavedChanges}
        handleResetChanges={handleResetChanges}
        handleSaveChanges={handleSaveEvent}
        isLoading={isLoading}
      />
    </div>
  )
}

export default EventToolbar

EventToolbar.propTypes = {
  currentTab: PropTypes.oneOf(['edit', 'settings', 'pages', 'result']),
  isPublished: PropTypes.bool,
  isLoading: PropTypes.bool,
  hasUnsavedChanges: PropTypes.bool,
  previewLink: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  handlePublishEvent: PropTypes.func,
  handleUnpublishEvent: PropTypes.func,
  handleSaveEvent: PropTypes.func,
  handleResetChanges: PropTypes.func,
  handleDeleteEven: PropTypes.func
}
