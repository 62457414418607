/** @jsxImportSource @emotion/react */
import Toggle from 'components/Toggle'
import React, {
  ChangeEvent,
  ClipboardEvent,
  useCallback,
  useContext,
  useEffect
} from 'react'
import { useTranslation } from 'react-i18next'
import SettingsModule from '../SettingsModule'
import { ConfirmationEmailSettings } from './types'
import InputContainer from 'components/Inputs/Container'
import Text from '../../Inputs/Text'
import { ErrorContext } from '../../Contexts/ErrorProvider'

interface BccSettingsProps {
  confirmationEmail: ConfirmationEmailSettings
  updateEmailSettings: (val: Partial<ConfirmationEmailSettings>) => void
  confirmationEmailActive: boolean
  setHasBccEmailErrors: (hasBccEmailErrors: boolean) => void
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const BccSettings: React.FC<BccSettingsProps> = ({
  confirmationEmail,
  updateEmailSettings,
  confirmationEmailActive,
  setHasBccEmailErrors
}) => {
  const { t } = useTranslation()
  const { error, addError, clearError } = useContext(ErrorContext)

  const validateBccEmail = useCallback(
    (confirmationEmail: ConfirmationEmailSettings): void => {
      const { bcc_email = '', bcc_email_confirm = '', enabled = false } =
        confirmationEmail?.bcc || {}

      if (enabled) {
        if (bcc_email !== bcc_email_confirm) {
          addError('bcc_email', t('bcc_validation_dont_match'))
          setHasBccEmailErrors(true)
        } else if (bcc_email && !emailRegex.test(bcc_email)) {
          addError('bcc_email', t('bcc_validation_invalid'))
          setHasBccEmailErrors(true)
        } else if (bcc_email === '' && bcc_email_confirm === '') {
          addError('bcc_email', 'Email must not be empty')
          setHasBccEmailErrors(true)
        } else {
          clearError('bcc_email')
          setHasBccEmailErrors(false)
        }
      } else {
        clearError('bcc_email')
        setHasBccEmailErrors(false)
      }
    },
    [setHasBccEmailErrors, clearError, addError, t]
  )

  useEffect(() => {
    validateBccEmail(confirmationEmail)
  }, [confirmationEmail, validateBccEmail])

  const handleToggleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (confirmationEmailActive) {
      const newValue: Partial<ConfirmationEmailSettings> = {
        ...confirmationEmail,
        bcc: {
          ...confirmationEmail.bcc,
          enabled: e.target.checked,
          bcc_email: '',
          bcc_email_confirm: ''
        }
      }

      updateEmailSettings(newValue)
    }
  }

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = e.target
    const newValue = {
      ...confirmationEmail,
      bcc: {
        ...confirmationEmail.bcc,
        [name]: value
      }
    }
    updateEmailSettings(newValue)
    validateBccEmail(confirmationEmail)
  }

  const disableCopyPaste = (e: ClipboardEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const { enabled = false, bcc_email = '', bcc_email_confirm = '' } =
    confirmationEmail?.bcc || {}

  const bccErrorText = error?.data?.data?.bcc_email
  const bccIsInvalid: boolean = error?.data?.data?.bcc_email
  const bccAndConfirmFieldsNotEmpty: boolean =
    bcc_email !== '' && bcc_email_confirm !== ''

  return (
    <div id="bcc_container">
      <SettingsModule
        title={t('bcc_container_label')}
        className="CalendarSettings"
        description={t('bcc_container_description')}
        rightContent={
          <div className="SettingsModuleSpacer">
            <Toggle
              size="large"
              id="bcc_enabled"
              name="bcc_enabled"
              handleChange={handleToggleChange}
              disabled={!enabled || !confirmationEmailActive}
              label={undefined}
            />
          </div>
        }
        size={undefined}
        children={
          enabled && (
            <div className="bcc_inner_container">
              <div className="BCCRow">
                <div className="BCCColumn">
                  <InputContainer
                    id="bcc_email"
                    label={t('bcc_email_input_label')}>
                    <Text
                      value={bcc_email}
                      handleChange={handleInputChange}
                      placeholder="name@domain.com"
                      name="bcc_email"
                    />
                  </InputContainer>
                </div>
                <div className="BCCColumn">
                  <InputContainer
                    id="bcc_email_confirm"
                    label={t('bcc_email_confirm_input_label')}>
                    <Text
                      value={bcc_email_confirm}
                      handleChange={handleInputChange}
                      onCopy={e => disableCopyPaste(e)}
                      onPaste={e => disableCopyPaste(e)}
                      onCut={e => disableCopyPaste(e)}
                      placeholder="name@domain.com"
                      name="bcc_email_confirm"
                    />
                  </InputContainer>
                </div>
                <div className="StatusIcon">
                  {bccAndConfirmFieldsNotEmpty && bccIsInvalid && (
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        id="Union"
                        d="M10.4747 8.00027L15.2371 12.7627L12.7622 15.2376L7.99978 10.4751L3.23735 15.2376L0.762472 12.7627L5.5249 8.00027L0.762207 3.23757L3.23708 0.762695L7.99978 5.52539L12.7625 0.762695L15.2373 3.23757L10.4747 8.00027Z"
                        fill="#FE2F1F"
                      />
                    </svg>
                  )}
                  {bccAndConfirmFieldsNotEmpty && !bccIsInvalid && (
                    <svg
                      width="20"
                      height="16"
                      viewBox="0 0 20 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        id="Check"
                        d="M7.8418 10.2266L16.9629 0.5L19.4434 3.15625L7.86133 15.5L7.58789 15.2266L7.56836 15.2461L0.556641 8.15625L3.17383 5.5L7.8418 10.2266Z"
                        fill="#5EBE6F"
                      />
                    </svg>
                  )}
                </div>
              </div>

              {bccAndConfirmFieldsNotEmpty && bccIsInvalid && (
                <div className="BCCRow BCCErrorContainer">{bccErrorText}</div>
              )}
            </div>
          )
        }
      />
    </div>
  )
}
