import React from 'react'
import './ConfirmationEmail.scss'

// Inputs

import { CalendarReminderSettings, CalendarSettings } from './CalendarSettings'
import { EmailSettings } from './EmailSettings'
import { QrTicketSettings } from './QrCodeSettings'
import { ConfirmationEmailSettings } from './types'
import { AllowGuestToCancelSettings } from './AllowGuestToCancelSettings'
import { EventResponse } from 'misc/api/hooks/event.types'
import { BccSettings } from './Bcc'

interface ConfirmationEmailState {
  data: {
    confirmation_email?: ConfirmationEmailSettings
    calendar_reminder?: CalendarReminderSettings
  }
  guestlists_id?: string[] | null
}

interface ConfirmationEmailProps {
  currentState: ConfirmationEmailState
  updateEmailSettings: (val: Partial<ConfirmationEmailSettings>) => void
  updateCalendarSettings: (val: Partial<CalendarReminderSettings>) => void
  hasUnsavedChanges?: boolean
  event: EventResponse | null
  setHasBccEmailErrors: (hasBccEmailErrors: boolean) => void
}

const ConfirmationEmail: React.FC<ConfirmationEmailProps> = ({
  currentState: {
    data: { confirmation_email, calendar_reminder = {} },
    guestlists_id
  },
  updateEmailSettings,
  updateCalendarSettings,
  hasUnsavedChanges,
  event,
  setHasBccEmailErrors
}) => {
  if (!confirmation_email) {
    return null
  }

  return (
    <>
      <EmailSettings
        confirmationEmail={confirmation_email}
        hasUnsavedChanges={hasUnsavedChanges}
        updateEmailSettings={updateEmailSettings}
        guestlistId={guestlists_id ? guestlists_id[0] : null}
      />
      {confirmation_email.enabled ? (
        <CalendarSettings
          confirmationEmailActive={confirmation_email.enabled}
          calendarReminder={calendar_reminder}
          updateCalendarSettings={updateCalendarSettings}
        />
      ) : null}

      {confirmation_email.enabled ? (
        <AllowGuestToCancelSettings
          confirmationEmail={confirmation_email}
          updateEmailSettings={updateEmailSettings}
          confirmationEmailActive={confirmation_email.enabled}
          event={event}
        />
      ) : null}

      {confirmation_email.enabled ? (
        <QrTicketSettings
          confirmationEmail={confirmation_email}
          updateEmailSettings={updateEmailSettings}
          confirmationEmailActive={confirmation_email.enabled}
        />
      ) : null}

      {confirmation_email.enabled ? (
        <BccSettings
          confirmationEmail={confirmation_email}
          updateEmailSettings={updateEmailSettings}
          confirmationEmailActive={confirmation_email.enabled}
          setHasBccEmailErrors={setHasBccEmailErrors}
        />
      ) : null}
    </>
  )
}

export default ConfirmationEmail
