import React, { createContext, useCallback, useState } from 'react'

export const ErrorContext = createContext()

const ErrorProvider = ({ children }) => {
  const [error, setError] = useState({})

  const clearError = useCallback(
    errorKey => {
      setError(prevError => {
        return {
          ...prevError,
          data: {
            ...(prevError.data ? prevError.data : {}),
            data: {
              ...(prevError.data && prevError.data.data
                ? prevError.data.data
                : {}),
              [errorKey]: null
            }
          }
        }
      })
    },
    [setError]
  )

  const addError = useCallback(
    (errorKey, message) => {
      setError(prevError => {
        return {
          ...prevError,
          data: {
            ...(prevError.data ? prevError.data : {}),
            data: {
              ...(prevError.data && prevError.data.data
                ? prevError.data.data
                : {}),
              [errorKey]: message
            }
          }
        }
      })
    },
    [setError]
  )

  return (
    <ErrorContext.Provider
      value={{
        error,
        setError,
        clearError,
        addError
      }}>
      {children}
    </ErrorContext.Provider>
  )
}

export default ErrorProvider
