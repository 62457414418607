import './EventLocation.scss'
import React, { useReducer, useEffect, useContext } from 'react'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

import LocationPicker from 'components/LocationPicker'
import InputContainer from 'components/Inputs/Container'
import Text from 'components/Inputs/Text'
import { SettingsModule } from 'components/Settings/SettingsModule'

import { useTranslation } from 'react-i18next'
import { ErrorContext } from '../../../Contexts/ErrorProvider'

const setVenue = 'set-venue'
const updateLocation = 'update-location'
const setAddress = 'set-address'
const updateGoogleMapsUrl = 'update-google-maps-url'
const updateCoordinates = 'update-coordinates'

const reducer = (state, action) => {
  switch (action.type) {
    case updateLocation:
      return {
        ...state,
        address: action.address,
        google_maps_url: action.google_maps_url,
        latitude: action.latitude,
        longitude: action.longitude,
        venue_name: action.venue_name
      }
    case setVenue:
      return { ...state, venue_name: action.venue_name }
    case setAddress:
      return { ...state, address: action.address }
    case updateGoogleMapsUrl:
      return { ...state, google_maps_url: action.google_maps_url }
    case updateCoordinates:
      return {
        ...state,
        latitude: action.latitude,
        longitude: action.longitude
      }
    default:
      return state
  }
}

const EventLocation = ({ location, handleLocationChange }) => {
  const [state, dispatch] = useReducer(reducer, {
    latitude: null,
    longitude: null
  })
  const { t } = useTranslation()
  const { error, setError } = useContext(ErrorContext)
  const isInvalid =
    error &&
    error.data &&
    (!error.data.data || Object.keys(error.data.data).indexOf('url') !== -1)

  const handleSelectAddress = async (address, placeId) => {
    let newState = {
      ...state,
      address
    }

    try {
      const geocodedAddress = await geocodeByAddress(address)
      const { lat: latitude, lng: longitude } = await getLatLng(
        geocodedAddress[0]
      )

      // Get the formatted address from the geocoding result
      const formattedAddress = geocodedAddress[0].formatted_address

      // Create the Google Maps URL with encoded parameters
      const encodedPlaceName = encodeURIComponent(formattedAddress)
      const google_maps_url = `https://www.google.com/maps/search/?api=1&query=${encodedPlaceName}&query_place_id=${placeId}`

      newState = {
        ...newState,
        latitude,
        longitude,
        google_maps_url
      }
    } catch (error) {
      console.log('Error', error)
    }

    dispatch({ type: updateLocation, ...newState })
    handleLocationChange(newState)
  }

  const setGoogleMapsUrl = event => {
    handleLocationChange({
      ...state,
      google_maps_url: event.target.value
    })
    dispatch({ type: updateGoogleMapsUrl, google_maps_url: event.target.value })
    if (error && error.data && error.data.data) {
      delete error.data.data.url
    }
    setError(error)
  }

  const handleSetAddress = address => {
    handleLocationChange({
      ...state,
      address
    })
    dispatch({ type: setAddress, address })
  }

  const handleSetAvenue = event => {
    handleLocationChange({
      ...state,
      venue_name: event.target.value
    })
    dispatch({ type: setVenue, venue_name: event.target.value })
  }

  useEffect(() => {
    location && dispatch({ type: updateLocation, ...location })
  }, [location])

  return (
    <SettingsModule title={t('location')} description={t('helpTexts.location')}>
      <div className="EventLocationRow">
        <div className="EventLocationColumn">
          <InputContainer label={t('venue')}>
            <Text
              id="venue-name"
              label={t('venue')}
              handleChange={handleSetAvenue}
              value={state.venue_name}
              placeholder={t('venue-placeholder')}
            />
          </InputContainer>
        </div>
      </div>

      <div className="EventLocationRow">
        <div className="EventLocationColumn">
          <InputContainer label={t('search-address')}>
            <LocationPicker
              location={state}
              handleChange={handleSetAddress}
              handleSelect={handleSelectAddress}
            />
          </InputContainer>
        </div>

        <div className="EventLocationColumn">
          <InputContainer label={t('googleMapsLabel')}>
            <div className="EventLocationMapsContainer">
              <Text
                id="google-maps-link"
                className={isInvalid ? 'error-input' : ''}
                placeholder={t('googleMapsPlaceholder')}
                handleChange={setGoogleMapsUrl}
                handleFocus={event => event.target.select()}
                value={state.google_maps_url}
              />

              {state.google_maps_url &&
              state.google_maps_url.startsWith('https://') ? (
                <a
                  className="EventLocationOpen"
                  href={state.google_maps_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={t('openMap')}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                    <path d="M19.293 3.293a1 1 0 0 1 1.414 1.414l-9 9a1 1 0 1 1-1.414-1.414l9-9z" />
                    <path d="M19 5h-4a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V5zm-2 8.5a1 1 0 1 1 2 0v5.625A2.875 2.875 0 0 1 16.125 22H4.875A2.875 2.875 0 0 1 2 19.125V7.875A2.875 2.875 0 0 1 4.875 5h6.094a1 1 0 0 1 0 2H4.875A.875.875 0 0 0 4 7.875v11.25c0 .483.392.875.875.875h11.25a.875.875 0 0 0 .875-.875V13.5z" />
                  </svg>
                </a>
              ) : null}
            </div>
            {isInvalid && (
              <p className="SettingsModuleDescription error">
                {t('helpTexts.locationError')}
              </p>
            )}
          </InputContainer>
        </div>
      </div>
    </SettingsModule>
  )
}

export default EventLocation
